const urlId = new URLSearchParams(window.location.search);

export const environment = {
  production: true,
  baseApiUrl: 'https://api-dev.medaula.com.br/api/medaula',
  baseUrl: 'https://api-dev.medaula.com.br/api',
  baseUrlImage: 'https://api-dev.medaula.com.br',
  baseUrlDownload: 'https://api-dev.medaula.com.br/api/files/download',
  baseUrlUpload: 'https://api-dev.medaula.com.br/api/files/upload',
  medAulaId: urlId.get('source')
};
